import React, { Component, Fragment } from "react";
import { withPrefix } from 'gatsby';
import PropTypes from "prop-types";
import classNames from "classnames";
import gsap from "gsap";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Lazy } from "swiper"
import LoaderIcon from "../../../assets/slider_loader_icon.svg";
import ArrowLeftIcon from "../../../assets/arrow_left_icon.svg";
import ArrowRightIcon from "../../../assets/arrow_right_icon.svg";
import BackgroundVideo from "./BackgroundVideo";

SwiperCore.use([Lazy, Pagination, Navigation]);

export default class MediaSlider extends Component
{
    static propTypes = {
        device: PropTypes.any.isRequired,
        classes: PropTypes.string,
        autoplay: PropTypes.shape({                         // AutoPlay options
            enabled: PropTypes.bool,                        // Enable AutoPlay
            delay: PropTypes.number,                        // Delay between transitions 
            firstDelay: PropTypes.number,                   // Delay between transitions 
            disableOnInteraction: PropTypes.bool,           // Set to false and autoplay will not be disabled after user interactions
        }),
        slideOffset: PropTypes.number,                      // amount to move when transitioning
        slides: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,                         // image || video
            url: PropTypes.string,                          // image url
            alt: PropTypes.string,                          // alt tag
            video: PropTypes.shape({                        // video details
                mobile: PropTypes.string,                   // mobile url
                desktop: PropTypes.string,                  // desktop url
            }),
        })),
        sliderOptions: PropTypes.shape({
            preloadImages: PropTypes.bool,
            lazy: PropTypes.shape({                         // Swiper Lazy options
                preloaderClass: PropTypes.string,
                loadedClass: PropTypes.string
            }),
            updateOnImagesReady: PropTypes.bool,            // Swiper will be reinitialized after all inner images (<img> tags) are loaded
            speed: PropTypes.number,                        // Duration of transition between slides (in ms)
            grabCursor: PropTypes.bool,                     // See the "grab" cursor when hover
            loop: PropTypes.bool,
            loopPreventsSlide: PropTypes.bool,
            shortSwipes: PropTypes.bool,                    // Disable short swipes
            longSwipes: PropTypes.bool,                     // Disable long swipes
            longSwipesRatio: PropTypes.number,              // Ratio to trigger swipe to next/previous slide during long swipes
            resistance: PropTypes.bool,                     // Control resistance ratio
            resistanceRatio: PropTypes.number,              // Control resistance ratio
            watchSlidesProgress: PropTypes.bool,            // Calculate each slides progress
            loopAdditionalSlides: PropTypes.number,         // Addition number of slides that will be cloned after creating of loop
            navigation: PropTypes.shape({                   // Swiper Navigation options
                nextEl: PropTypes.string,
                prevEl: PropTypes.string,
                disabledClass: PropTypes.string
            }),
            pagination: PropTypes.shape({                   // Swiper Pagination options
                el: PropTypes.string,
                type: PropTypes.string,
                bulletClass: PropTypes.string,
                bulletActiveClass: PropTypes.string,
                hideOnClick: PropTypes.bool,
                clickable: PropTypes.bool
            })
        }),
    }
    
    static defaultProps = {
        classes: "",
        autoplay: {
            enabled: true,
            delay: 4000,
            firstDelay: 19000,
            disableOnInteraction: true
        },
        slideOffset: 0.5,
        slides: [],
        sliderOptions: {
            preloadImages: false,
            lazy: {
                preloaderClass: "slider-lazy",
                loadedClass: "slider-loaded",
            },
            updateOnImagesReady: true,              
            speed: 1500,                            
            grabCursor: true,                       
            loop: false,
            loopPreventsSlide: true,
            shortSwipes: true,                      
            longSwipes: true,                       
            longSwipesRatio: 0.5,                   
            resistance: true,                       
            resistanceRatio: 0.85,                  
            watchSlidesProgress: true,              
            loopAdditionalSlides: 0,                
            navigation: {
                nextEl: ".slider-next",
                prevEl: ".slider-prev",
                disabledClass: "slider-nav-disabled"
            },
            pagination: {
                el: ".slider-page",
                type: "bullets",
                bulletClass: "s-bullet",
                bulletActiveClass: "s-bullet-active",
                hideOnClick: false,
                clickable: true,
                renderBullet: (index, className) => {
                    let bullet = `<span tabindex="0" class="${className}" data-index="${index}" role="button" aria-label="Slide #${index + 1}">`;
                    bullet += `<div class="s-icon"></div>`;
                    bullet += `<svg class="s-circle" viewBox="0 0 100 100"><circle cx="50" cy="50" r="42" fill="transparent"></circle></svg>`;
                    bullet += `</span>`;
                    return bullet;
                }
            }
        }
    }

    constructor(props)
    {
        super(props);

        this.slider = null;
        this.autoplayTimer = null;
        this.isAutoPlay = props.autoplay.enabled;
        this.onKey = this.onKey.bind(this);
    }

    componentWillUnmount()
    {
        this.disableAutoPlay();

        const slider = this.slider;
        for (let i = 0; i < slider.pagination.bullets.length; i++) 
            slider.pagination.bullets[i].removeEventListener("keypress", this.onKey);

        this.slider = null;
    }

    onInit(slider)
    {
        // console.log("[media_slider].onInit()", slider);

        this.slider = slider;
    }

    onSlideChangeTransitionStart(slider)
    {
        //console.log("[media_slider].onSlideChangeTransitionStart()", slider);
    }
    
    onSlideChangeTransitionEnd(slider)
    {
        // console.log("[media_slider].onSlideChangeTransitionEnd()", slider);

        const index = slider.realIndex;
        
        // check autoplay is enabled and fire timer on first slide
        const { autoplay } = this.props;
        if (this.isAutoPlay)
        {
            slider.pagination.bullets[index].classList.add("auto-start");
            this.autoplayTimer = setTimeout(() => this.onAutoplayTimerEnd(), index === 0 ? autoplay.firstDelay : autoplay.delay);
        }
    }
    
    onProgress(slider)
    {
        // console.log("[media_slider].onProgress()", slider);

        const { slideOffset } = this.props;

        for (let i = 0; i < slider.slides.length; i++) 
        {
            const slideProgress = slider.slides[i].progress;
            const innerOffset = slider.width * slideOffset;
            const innerTranslate = slideProgress * innerOffset;
            const bgimg = slider.slides[i].getElementsByClassName("slider-fig")[0];
            if (bgimg) bgimg.style.transform = "translateX(" + innerTranslate + "px)";
        }
    }
    
    onTouchStart(slider)
    {
        // console.log("[media_slider].onTouchStart()", slider);

        for (let i = 0; i < slider.slides.length; i++) 
        {
            slider.slides[i].style.transition = "";
        }
    }
    
    onSetTransition(slider, transition)
    {
        // console.log("[media_slider].onSetTransition()", slider, transition);

        for (let i = 0; i < slider.slides.length; i++) 
        {
            slider.slides[i].style.transition = transition + "ms";
            const bgimg = slider.slides[i].getElementsByClassName("slider-fig")[0];
            if (bgimg) bgimg.style.transition = transition + "ms";
        }
    }

    onLazyImageReady(slider, slideEl, imageEl)
    {
        // console.log("[media_slider].onLazyImageReady()", slider, slideEl, imageEl);
        
        const { autoplay } = this.props;

        if (!this.autoplayTimer)
        {
            // add key events to each bullet
            for (let i = 0; i < slider.pagination.bullets.length; i++) 
                slider.pagination.bullets[i].addEventListener("keypress", this.onKey);

            // check autoplay is enabled and fire timer on first slide
            if (this.isAutoPlay)
            {
                
                slider.pagination.bullets[0].classList.add("auto-start");
                this.autoplayTimer = setTimeout(() => this.onAutoplayTimerEnd(), autoplay.firstDelay);
            }
        }
    }

    onAutoplayTimerEnd()
    {
        const { sliderOptions } = this.props;

        const index = this.slider.realIndex;
        const total = this.slider.slides.length;

        let nextIndex;
        if (index < total - 1) nextIndex = index + 1;
        else nextIndex = 0;

        this.slider.pagination.bullets[index].classList.remove("auto-start");;
        this.slider.slideTo(nextIndex, sliderOptions.delay);
    }

    onMeta(params)
    {
        // console.log("[media_slider].onMeta()", params);

        const { container } = params;
        this._tween = gsap.to(container.current, { delay: 1, opacity: 1, duration: 3, ease: "cubic.out" });
    }

    onKey(e)
    {
        // console.log("[media_slider].onKey()", e);

        // if not the ENTER KEY(13), ignore
        if (e.charCode !== 13) return;
       
        this.disableAutoPlay();

        if (e.target.classList.contains("slider-next"))
        {
            console.log("[media_slider].NEXRT()", e.target);
            this.slider.slideNext(1500);
        }            
        else if (e.target.classList.contains("slider-prev"))
        {
            this.slider.slidePrev(1500);
        }
        else if (e.target.classList.contains("s-bullet"))
        {
            const pageID = parseInt(e.target.getAttribute("data-index"), 10);
            this.slider.slideTo(pageID, 1500);
        }
            
    }

    onSliderInteract(slider, event)
    {
        // console.log("[media_slider].onSliderInteract()", slider, event);

        const { autoplay } = this.props;

        // checks for movement/drag interaction
        if (!event)
        {
            if (this.isAutoPlay && autoplay.disableOnInteraction)
            {
                this.disableAutoPlay();
            }
        }

        // check for button interaction
        const target = event && event.target;
        let isButton = false;
        
        if (target) 
        {
            if (target.classList.contains("slider-next")
            || target.classList.contains("slider-prev") 
            || target.classList.contains("s-bullet"))
            {
                isButton = true;
            }
        }

        if (isButton && this.isAutoPlay)
        {
            if (autoplay.disableOnInteraction)
            {
                this.disableAutoPlay();
            }
        }
    }

    disableAutoPlay()
    {
        this.isAutoPlay = false;
        clearTimeout(this.autoplayTimer);
        for (let i = 0; i < this.slider.pagination.bullets.length; i++) 
        {
            const nextBullet = this.slider.pagination.bullets[i];
            nextBullet.classList.remove("auto-start");
        }
    }

    render()
    {
        const { device, classes, slides, sliderOptions } = this.props;

        return <Fragment>

            <div className={classNames("slider-group", "bg-layer", classes)}>

                <Swiper 
                    className={classNames("slider")}
                    {...sliderOptions}
                    onInit={(slider) => this.onInit(slider)}
                    onSlideChangeTransitionStart={(slider) => this.onSlideChangeTransitionStart(slider)}
                    onSlideChangeTransitionEnd={(slider) => this.onSlideChangeTransitionEnd(slider)}
                    onProgress={(slider) => this.onProgress(slider)}
                    onTouchStart={(slider) => this.onTouchStart(slider)}
                    onSetTransition={(slider, transition) => this.onSetTransition(slider, transition)}
                    onLazyImageReady={(slider, slideEl, imageEl) => this.onLazyImageReady(slider, slideEl, imageEl)}
                    onSliderFirstMove={(slider) => this.onSliderInteract(slider)}
                    onClick={(slider, event) => this.onSliderInteract(slider, event)}
                >
                    {slides.map((item, index) => {
                        const { type, url, alt, video, position } = item;
                        
                        if (type === "video")
                        {
                            return <SwiperSlide key={index} className={`slide`}>
                                <figure className="slider-fig swiper-lazy" data-background={url}>
                                {<BackgroundVideo 
                                    device={device}
                                    autoPlay={true}
                                    classes="slide-video-group"
                                    videoClass="slide-video"
                                    desktop={video.desktop}
                                    scrnSize={false}
                                    isBackground={true}
                                    onMeta={(params) => this.onMeta(params)}
                                />}
                                </figure>
                                <div className="slider-lazy">
                                    <div className="lazy-icon"><LoaderIcon/></div>
                                </div>
                            </SwiperSlide>
                        }
                        else // (type === "image")
                        {
                            let figStyle = {};
                            if (position) figStyle.backgroundPosition = position;

                            return <SwiperSlide key={index} className="slide">
                                <figure className="slider-fig swiper-lazy" data-background={url} style={figStyle}>
                                    {<img width="2" height="2" src={withPrefix("static/images/gallery/preload.jpg")} className="preload" alt={alt}/>}
                                </figure>
                                <div className="slider-lazy">
                                    <div className="lazy-icon"><LoaderIcon/></div>
                                </div>
                            </SwiperSlide>
                        }
                    })}

                    <div className={"bg-gradient-placeholder"}>
                        <div className={"bg-overlay blue show-gradient"}></div>
                    </div>
                    
                    <nav className="slider-nav">
                        <div className="slider-prev" role="button" aria-label="Previous Slide" tabIndex={0} onKeyPress={this.onKey}><ArrowLeftIcon/></div>
                        <div className="slider-page">
                            {slides.map((item, index) => {
                                return <div key={index} className="s-bullet"></div>
                            })}
                        </div>
                        <div className="slider-next" role="button" aria-label="Next Slide" tabIndex={0} onKeyPress={this.onKey}><ArrowRightIcon/></div>
                    </nav>

                    <div className="slider-nav-label">Use the arrows to navigate</div>
                </Swiper>

            </div>

        </Fragment>
    }
}