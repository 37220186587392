/**
 *
 * Gallery Page
 * 
*/

import React, { Fragment } from "react"
import classNames from "classnames";
import { withPrefix } from 'gatsby';
import BasePage from "./BasePage"
import Module from "../module/Module";
import ModuleContent from "../module/ModuleContent";
import RichLayoutRow from "../richlayout/RichLayoutRow";
import RichLayoutColumn from "../richlayout/RichLayoutColumn";
import RichLayoutBody from "../richlayout/RichLayoutBody";
import Footer from "../Footer";
import MediaSlider from "../media/MediaSlider";
import FocusManager, { FOCUSABLE } from "../../system/FocusManager";
import { 
    galleryData, 
} from "../../data/PageAssets";

export default class GalleryPage extends BasePage
{
    constructor(props)
    {
        super(props);

        this._isLazy = true;                        // lazy loading 
        this._assetPath = "gallery";                // asset folder for page
        this._assets = galleryData();               // asset data

        this._focus = null;
        this._focusLast = 0;

        this.state = {
            hasLoaded: false,
            hasLazyLoaded: false,
            hasResized: false,
            slides: []
        }
    }

    componentDidMount()
    {
        super.componentDidMount();
        
        // start initial asset loading...
        this.startAssets({ 
            isLazy: false, 
            onAssetEnd: () => this.onAssetEnd() 
        });
    }

    onAssetEnd()
    {
        const getVid = this.pVideoNam("gallery_loop");
        const path = withPrefix('static/images/gallery/');

        const slides = [
            { type: "video", url: this.assetRef("gallery_hero_shot").src(), video: { desktop: getVid, delay: this._isPhone ? 4 : 0 } },
            { type: "image", url: `${path}slider1.jpg`, alt: "Image Description", position: "16% center" },
            { type: "image", url: `${path}slider2.jpg`, alt: "Image Description" },
            { type: "image", url: `${path}slider3.jpg`, alt: "Image Description", position: "73% center" },
            { type: "image", url: `${path}slider4.jpg`, alt: "Image Description" },
            { type: "image", url: `${path}slider5.jpg`, alt: "Image Description" },
            { type: "image", url: `${path}slider6.jpg`, alt: "Image Description", position: "39% center" },
            { type: "image", url: `${path}slider7.jpg`, alt: "Image Description", position: "59% center" },
            { type: "image", url: `${path}slider8.jpg`, alt: "Image Description" },
            { type: "image", url: `${path}slider9.jpg`, alt: "Image Description" },
        ];

        this.setState({ hasLoaded: true, slides });
    }

    componentDidUpdate(prevProps, prevState)
    {
        const { hasLoaded: hl, hasLazyLoaded: lz } = this.state;

        if (hl !== prevState.hasLoaded)
        {
            this.initPage();
            return;
        }

        if (lz !== prevState.hasLazyLoaded)
        {
            this.afterPageLoad();
            // this.onResize();
            this.updateLayout();
        }
    }

    afterPageLoad()
    {
        super.afterPageLoad();

        this.setFocus();
    }

    menuViewed()
    {
        this.removeFocus();
    }
    
    menuClosed()
    {
        this.setFocus();
    }

    onViewVideo()
    {
        this.removeFocus();
        
        super.onViewVideo();
    }

    onCloseVideo()
    {
        super.onCloseVideo();

        this.setFocus();
    }

    setFocus(props)
    {
        const page = this._pageRef;
        const focusable = [];
        let i, pF;
        
        // page header
        const header = document.getElementById("__header");
        focusable.push(header.querySelector(".logo-link"));
        focusable.push(header.querySelector(".sound-button"));
        focusable.push(header.querySelector(".menu-icon-button"));

        // slider module
        const mSl = page.getElementsByClassName("m-slideshow")[0];
        
        // slider module title and button
        focusable.push(mSl.querySelector(".header-module-top .heading"));
        focusable.push(mSl.querySelector(".header-module-bottom .exp-video-btn .btn-title"));

        // slider navigation and pagination        
        const nav = mSl.querySelector(".slider-group .slider-nav");
        const navPage = nav.querySelector(".slider-page");
        
        // slider navigation previous button
        focusable.push(nav.querySelector(".slider-prev"));

        // slider pagination bullets
        const bullets = [].slice.call(navPage.querySelectorAll(".s-bullet"));
        for (i = 0; i < bullets.length; i++) focusable.push(bullets[i]);
        
        // slider navigation next button
        focusable.push(nav.querySelector(".slider-next"));

         // footer module
        pF = page.getElementsByClassName("m-footer")[0];
        pF = [].slice.call(pF.querySelectorAll(FOCUSABLE));
        for (i = 0; i < pF.length; i++) focusable.push(pF[i]);

        // init focus controller
        this._focus = new FocusManager({
            focusable: focusable,
            isGuided: true,
            ignoreEsc: true,
            lastIdx: this._focusLast
        });
        this._focus.init();
    }

    removeFocus()
    {
        if (this._focus) 
        {
            this._focusLast = this._focus.lastIdx;
            this._focus.dispose();
            this._focus = null;
        }
    }

    dispose()
    {
        super.dispose();
        this.removeFocus();
    }

    triggerVideo(e)
    {
        e.preventDefault();
        this.onViewVideo();
    }

    render()
    {
        const { hasLoaded, hasLazyLoaded: lz, hasResized: rz, slides } = this.state;

        const mProps = {
            hasLazyLoaded: lz,
            hasResized: rz,
            device: this._devSize
        }

        return (
            <Fragment>
                <article ref={div => this._pageRef = div} className={"page gallery"}>
                    <div className={"page-content"}>
                        <h1>Gallery</h1>
                    </div>
                    {
                        hasLoaded && 
                        <div className="modules">
                    
                            <Module classes="m-slideshow" 
                                isHeader={true} 
                                isFullSize={true} 
                                {...mProps}
                            >
                                <MediaSlider classes="bg-layer" slides={slides} hasResized={rz} device={this._devSize} />
                                <ModuleContent 
                                    type="module-slider"
                                    textContentClass="text-align-center align-center v-bottom"
                                    isFullSize={true}
                                    heading="Gallery"
                                >
                                    <div className={classNames("styled-btn-01", "exp-video-btn")}>
                                        <div 
                                            role="button"
                                            aria-label="Experience video"
                                            tabIndex={0}
                                            className="btn-title a-lnk" 
                                            onKeyPress={(e) => this.triggerVideo(e)}
                                            onClick={(e) => this.triggerVideo(e)}>Play Video</div>
                                        {/*<a href="#" 
                                            aria-label="Experience video"
                                            tabIndex={0}
                                            className="btn-title" 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.onViewVideo();
                                            }}>Play Video</a>*/}
                                    </div>
                                </ModuleContent>
                            </Module>

                            <Module 
                                device={this._devSize}
                                hasRichLayout={true} 
                                classes="m-footer" 
                                backgroundColor="#0a0a0a" 
                                animate="footer"
                                hasLazyLoaded={lz}
                            >
                                <ModuleContent classes="full-width">
                                    <RichLayoutRow classes="">
                                        <RichLayoutColumn classes="flex-v-align-center">
                                            <RichLayoutBody>
                                                <Footer owner={this} pageRef={this._pageRef} hasLazyLoaded={lz}/>
                                            </RichLayoutBody>
                                        </RichLayoutColumn> 
                                    </RichLayoutRow>
                                </ModuleContent>
                            </Module>
                        </div>
                    }

                </article>
            </Fragment>
        )
    }  
}